import React from "react";
import { Container, Row, Col, Progress } from "reactstrap";
import Card from "reactstrap/lib/Card";

import { FaCircle } from "react-icons/fa";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      {events.map((e) => (
        <div
          style={{
            backgroundColor: "#000000",
          }}
          className="section"
          data-parallax={true}
          ref={pageHeader}
        >
          <div className="overlay-primary"></div>
          <Container>
            <Row className="justify-content-center mb-5">
              <Col md={12}>
                <div className="container-fluid row justify-content-center">
                  <Col lg={6} xs={12} className={`${!size && "text-center pb-3"}`}>
                    <img
                      src={e.logo}
                      style={{ maxWidth: size ? "450px" : "250px" }}
                      width="100%"
                      alt="dt22"
                    />
                  </Col>
                  <Col lg={6} xs={12} className="align-self-center">
                    <h3
                      className="text-400 mt-0 text-white"
                      style={{
                        borderTop: !size && "1px solid #fff",
                        paddingTop: !size && "20px",
                        borderLeft: size && "1px solid #fff",
                        paddingLeft: size && "20px",
                      }}
                    >
                      {e.location},
                      <br />
                      {e.date}
                    </h3>
                  </Col>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={4}>
                <div className="container-fluid row">
                  {e.stats.map((c) => (
                    <Col lg={6} xs={6} className="text-center text-white mb-3">
                      <img
                        src={require(`assets/icons/${c.image}`)}
                        style={{ maxWidth: "70px" }}
                        width="100%"
                        alt="dt22"
                      />
                      <h4 className="text-900 text-g-p mt-1">{c.value}</h4>
                      <p className="text-400 " style={{ fontSize: "16px" }}>
                        {c.title}
                      </p>
                    </Col>
                  ))}
                </div>
              </Col>
              <Col lg={4} className={`${!size && "text-center mb-3"}`}>
                <img
                  src={e.piechart}
                  style={{ maxWidth: size ? "350px" : "250px" }}
                  width="100%"
                  alt="dt22"
                />
              </Col>
              <Col lg={4}>
                {e.profiles.map((p) => (
                  <h5 className="text-400 mt-0 text-white pt-2">
                    <FaCircle className="mr-3" style={{ color: p.color }} />
                    {p.title} - {p.value}
                  </h5>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
      ))}
    </>
  );
}

export default TextGrid;

const events = [
  {
    logo: require("assets/images/DT-LOGO-2023-Reverse.png"),
    location: "ADDRESS DUBAI MARINA HOTEL",
    date: "MARCH 14-15, 2023",
    stats: [
      { title: "SPEAKERS", value: 35, image: "p1.png" },
      { title: "ATTENDEES", value: 282, image: "p2.png" },
      { title: "EXHIBITORS", value: 12, image: "p3.png" },
      { title: "WORKSHOP DELEGATES", value: 21, image: "p4.png" },
    ],
    piechart: require("assets/icons/3piechart.png"),
    profiles: [
      { title: "ENGINEERING", value: "18.7%", color: "#3E8BA4" },
      { title: "CONSULTANTS", value: "20.1%", color: "#6A6C98" },
      { title: "SOFTWARE PROVIDERS", value: "30%", color: "#5F9696" },
      { title: "DEVELOPERS", value: "7.8%", color: "#858688" },
      { title: "PROJECT OWNERS", value: "7.4%", color: "#384881" },
      { title: "ARCHITECTURE", value: "4.6%", color: "#50467B" },
      { title: "CONTRACTOR", value: "6.1%", color: "#5990B5" },
      { title: "FM", value: "4.6%", color: "#5990B5" },
    ],
  },
  {
    logo: require("assets/images/DTSA-2022-LOGO.png"),
    location: "RIYADH AIRPORT MARRIOTT HOTEL",
    date: "NOVEMBER 8-9, 2022",
    stats: [
      { title: "SPEAKERS", value: 34, image: "p5.png" },
      { title: "ATTENDEES", value: 365, image: "p6.png" },
      { title: "EXHIBITORS", value: 14, image: "p7.png" },
      { title: "WORKSHOP DELEGATES", value: 81, image: "p8.png" },
    ],
    piechart: require("assets/icons/2piechart.png"),
    profiles: [
      { title: "PROJECT OWNERS & DEVELOPER", value: "25.7%", color: "#377B3C" },
      { title: "CONSULTANT ", value: "19%", color: "#4F8436" },
      { title: "SOFTWARE PROVIDER", value: "13.3%", color: "#9E9B3C" },
      { title: "ENGINEERING ", value: "21%", color: "#6B9890" },
      { title: "CONTRACTOR", value: "14.3%", color: "#78A342" },
      { title: "ARCHITECTURE", value: "1.9%", color: "#94BEA9" },
      { title: "FM ", value: "3.8%", color: "#49A2C0" },
    ],
  },
  {
    logo: require("assets/images/DT-UK-2023-White.png"),
    location: "IET LONDON SAVOY PLACE",
    date: "JUNE 20-21, 2023",
    stats: [
      { title: "SPEAKERS", value: 40, image: "p5.png" },
      { title: "ATTENDEES", value: 155, image: "p6.png" },
      { title: "EXHIBITORS", value: 5, image: "p7.png" },
      { title: "WORKSHOP DELEGATES", value: 37, image: "p8.png" },
    ],
    piechart: require("assets/icons/1piechart.png"),
    profiles: [
      { title: "CONSULTANT", value: "26.8%", color: "#362D6F" },
      { title: "ENGINEERING", value: "20.3%", color: "#42467B" },
      { title: "DEVELOPER", value: "5.9%", color: "#789FA8" },
      { title: "CONTRACTOR", value: "10.5%", color: "#AA7C95" },
      { title: "PROJECT OWNER ", value: "15%", color: "#AA7C95" },
      { title: "SOFTWARE PROVIDER", value: "13.7%", color: "#212961" },
      { title: "ARCHITECTURE", value: "4.6%", color: "#896C92" },
      { title: "FM", value: "3.3%", color: "#594A86" },
    ],
  },
  {
    logo: require("assets/images/DT-NZ-2024-White.png"),
    location: "MÖVENPICK HOTEL AUCKLAND, NEW ZEALAND",
    date: "MAY 8-9, 2024",
    stats: [
      { title: "SPEAKERS", value: 35, image: "p1.png" },
      { title: "ATTENDEES", value: 187, image: "p2.png" },
      { title: "EXHIBITORS", value: 4, image: "p3.png" },
      { title: "WORKSHOP DELEGATES", value: 35, image: "p4.png" },
    ],
    piechart: require("assets/icons/4piechart.png"),
    profiles: [
      { title: "CONSULTANT", value: "24.7%", color: "#BFBD4C" },
      { title: "ENGINEERING", value: "21.9%", color: "#877337" },
      { title: "DEVELOPER", value: "2.8%", color: "#5EB0E1" },
      { title: "CONTRACTOR", value: "6.2%", color: "#7FBB8B" },
      { title: "PROJECT OWNER ", value: "13.8%", color: "#F7F074" },
      { title: "SOFTWARE PROVIDER", value: "22.5%", color: "#444443" },
      { title: "ARCHITECTURE", value: "5.6%", color: "#896C92" },
    ],
  },
];

const content2 = [
  { title: "SPEAKERS", value: 34, image: "p5.png" },
  { title: "ATTENDEES", value: 365, image: "p6.png" },
  { title: "EXHIBITORS", value: 14, image: "p7.png" },
  { title: "WORKSHOP DELEGATES", value: 81, image: "p8.png" },
];
const content3 = [
  { title: "SPEAKERS", value: 40, image: "p5.png" },
  { title: "ATTENDEES", value: 155, image: "p6.png" },
  { title: "EXHIBITORS", value: 5, image: "p7.png" },
  { title: "WORKSHOP DELEGATES", value: 37, image: "p8.png" },
];

const content4 = [
  { value: "1", title: "DAY CONFERENCE", image: "p5.png" },
  { value: "1", title: "DAY WORKSHOPS", image: "p5.png" },
  { value: "17", title: "PRESENTATIONS", image: "p5.png" },
  { value: "3", title: "PANELS", image: "p5.png" },
  { value: "3", title: "WORKSHOPS", image: "p5.png" },
];
