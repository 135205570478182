import React, { useEffect } from "react";

function RegisterPage() {
  useEffect(() => {
    window.location.href =
      "https://drive.google.com/uc?id=1vIfWKj5JB9IUwXgx0lDAiDSq_fApnQSw&export=download";
  }, []);
  return <></>;
}
export default RegisterPage;
